.navbar-nav {
  font-weight: 500;
  font-size: 18px;
}

/* Remove underlines globally */
a {
  text-decoration: none !important;
  color: black !important;
}

/* If you want to remove underlines from buttons or other elements too */
button,
h1,
h2,
h3,
h4,
ul,
li,
p {
  text-decoration: none !important;
  list-style-type: none;
}


.bannerImg {
  /* position: absolute; */
  height: 300px;
  width: 100%;
  inset: 0px;
  object-fit: cover;
  object-position: center center;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  /* color: transparent; */
}

.cardsub {
  background-color: white;
  border-radius: 1rem;
}

.btnStyle {
  background: white !important;
  border-radius: 1rem !important;
}



nav {
  border-bottom: 1px solid beige !important;
}

.logo {
  width: 50%;
}

.footerMenu {
  list-style-type: none;
  line-height: 50px;
}

.footerMenu a {
  color: black !important;
}

/* .footerMenu:hover a {
  background-color: var(--secondary-color);
  color: #fff !important;
  padding: 2%;
} */

.bgc-primary {
  background-color: var(--primary-color);
}

@media only screen and (max-width: 991px) {
  .navbar-brand {
    width: 74%;
  }

}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.disco-text {
  font-size: 50px;
  /* Size of the text */
  font-weight: bold;
  text-transform: uppercase;
  background: linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet);
  background-size: 400% 400%;
  /* Makes the gradient large enough to animate */
  color: transparent;
  /* Make text color transparent to see the gradient */
  -webkit-background-clip: text;
  /* Clip the background to the text */
  animation: gradientAnimation 5s ease infinite;

  font-family: 'Arial', sans-serif;
}

/* Apply a gradient background animation */
.discoImg {
  background: linear-gradient(-45deg, rgb(238, 119, 82), rgb(231, 60, 126), rgb(35, 166, 213), rgb(35, 213, 171)) 99.9991% 50% / 400% 400%;
  animation: gradientAnimation 5s ease infinite;
  /* Ensures the height is the same as width for uniformity */
  width: 100%;
  /* Make sure the width is 100% */
  aspect-ratio: 1;
  /* Makes height and width always the same */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 1rem;
}

.card {
  width: 100% !important;
  height: 100% !important;
}

/* Set image properties */
.cardImg {
  width: 100%;
  /* Ensure the image fills the container's width */
  height: 100%;
  /* Ensure the image fills the container's height */
  object-fit: contain;
  /* Ensure the image covers the container area, maintaining aspect ratio */
}

.CYRbtnStyle {
  padding-bottom: .5rem;
  padding-top: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-image: linear-gradient(to right, #3b82f6, #06b6d4);
  font-weight: 500;
  width: 100%;
  border-radius: 1rem;
}

.boxCYR {
  line-height: 2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Custom shadow */
}

.shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}