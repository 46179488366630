body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --beercl: #F7B426;
  --primary-color: #00c4cc;
  --secondary-color: #7c2ae8;
  /* Define the color variable */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.formmain {
  background-color: var(--beercl);
  border-radius: 10px;
}

.prImg>div>img {
  width: 426px;
  height: 369px;
}

.bgimg {
  /* background-image: url(./img/homebannerbg.jpg); */
  position: relative;

}

.bgimg::before {
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(247, 193, 3, 0.9);
  /* Semi-transparent */
  z-index: 1;
}

.homecontent>img {
  width: 250px;
}

.homecontent>button {
  border-radius: .375rem;
  background-color: red;
  color: white;
  font-weight: 500;
  font-size: 20px;
}

.homeformbt>button {
  border-radius: 20px;
  color: white;
  font-weight: 500;
  font-size: 20px;
  width: 48%;
  color: black;
  border-radius: 20px;
  border: none;
  padding: 10px;
  cursor: pointer;

}

.formtoggle {
  border-radius: 20px;
  color: white;
  font-weight: 500;
  font-size: 20px;
  width: 48% !important;
  color: black;
  border-radius: 20px;
  border: none;
  padding: 10px;
  cursor: pointer;
  background-color: #f6f6f6;
  margin-top: 2%;

}

.findcabbtn {
  border-radius: 20px;
  color: white;
  font-weight: 500;
  font-size: 20px;
  width: 98% !important;
  color: black;
  border-radius: 20px;
  border: none;
  padding: 10px;
  cursor: pointer;
  background-color: black;
  color: white;

}

.findcabbtn:hover {
  background-color: var(--beercl);
  color: white;

}

.formtoggle>.radiocls:checked {
  background-color: var(--beercl);
}


.homeformbt>button.active {
  background-color: var(--beercl);

  /* Active button color */
  color: black;
  /* Text color for active button */
}

button:hover {
  background-color: red !important;
  color: white;
  /* Hover effect */
}

.homecontentform {
  text-align: center;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;

}



/* Form Ride with Ease */
.rwe {
  background-color: var(--beercl);
  font-weight: 500;
  padding: 10px 15px;
  text-align: start;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  font-size: 18px;

}

.homecontent {
  text-align: justify;
  color: white;
}

.form-control {
  margin-bottom: 10px !important;
  padding-left: 15px !important;
  padding: 7px !important;
  line-height: 2px !important;
  height: 54px !important;
  border-radius: 20px !important;
  padding-left: 25px !important;
  padding-right: 12% !important
}


.homeforminput>div {
  width: 100%;
}

.homeforminput>div>select {
  margin-bottom: 25px;
  padding-left: 15px;
  padding: 7px;
}

.input-group {
  position: relative;
}

.form-control {
  width: 100% !important;
}

.input-icon {
  margin-left: -30px;
  /* Adjust to overlap with the input */
  pointer-events: none;
  position: absolute;
  top: 14px;
  right: 36px;
  font-size: 25px;
  /* Prevents interference with input */
}

.FaLocationArrow {
  color: red;
}

.numcodedis {
  border-radius: 20px;
  color: white;
  font-weight: 500;
  font-size: 20px;
  color: black;
  border: none;
  cursor: pointer;
  max-width: fit-content;
  left: 25px;
  top: 10px;
}

/* .mbnumd::placeholder {
  padding-left: 15%;
} */

.mbnumd {
  padding-left: 7% !important;
}


.datepickclsmain {
  width: 100% !important;
  gap: 2%;
}

.datepickcls {
  width: 100% !important;
}

/* .datepickcls {
  width: 100%;
  margin-bottom: 10px;
  padding-left: 15px;
  line-height: 1.5px;
  border-radius: 20px;
} */


.datepickcls>div>input {
  width: 100%;
  margin-bottom: 10px;

  height: 54px;
  border-radius: 20px;
  padding-left: 25px;
}

.FaCalendarcls {
  right: 25px !important;
  background-color: white;

}



.custom-datepicker {
  width: 100% !important;
  margin-bottom: 10px !important;
  padding: 7px !important;
  height: 54px !important;
  border-radius: 25px !important;
  padding-left: 25px !important;
  border: 1px solid #dee2e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.custom-datepicker::-webkit-calendar-picker-indicator {
  padding-left: 2%;
  width: 20%;
  border-radius: 50%;
  top: 14px;
  /* right: 36px; */
  font-size: 25px;

}

/* Radio Style */
input[type="radio"] {
  display: none;
}


.custom-radio {
  width: 20px;
  height: 20px;
  background-color: var(--beercl);
  border: 2px solid #ccc;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  top: 3px;
  left: -3px;
  ;
}


input[type="radio"]:checked+.custom-radio {
  background-color: var(--beercl);
  width: 22px;
  height: 22px;
  border: 2px solid var(--beercl);
  border-radius: 50%;
  top: 5px;
  left: -3px;
  ;
}

/* Optional: Create a pseudo-element for the check indicator */
.custom-radio::after {
  content: '';
  width: 13px;
  height: 13px;
  background-color: black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

/* Show the inner dot when checked */
input[type="radio"]:checked+.custom-radio::after {
  display: block;

  /* Show the dot when checked */
}

/* Error Mesage */
.errorMes {
  text-align: start;
  padding-left: 29px;
  color: rgb(220, 53, 69);
  opacity: 1;
  font-weight: 500;
}

.errorMesdt {
  text-align: start;
  padding-left: 25px;
  color: rgb(220, 53, 69);
  opacity: 1;
  font-weight: 500;
}

/* Select Car style */
.selectCarcls {
  padding: 2%;
  margin: 1px;
  border: 1px solid black;
  width: 22% !important;
}

.selectCarImg {
  width: 100%;
}

th {
  text-align: start !important;
  padding-left: 10% !important;
}

td {
  text-align: start !important;
}



@media (max-width: 992px) {
  .mbnumd {
    padding-left: 30% !important;
  }

  .input-icon {
    font-size: 15px;

  }

  .datepickclsmain {
    /* margin-left: 5% !important; */
    margin-bottom: 5% !important;
  }

  .selectCarMaincls {
    justify-content: center;
  }

  .selectCarcls {
    padding: 2%;
    margin: 1px;
    border: 1px solid black;
    width: 45.666667% !important;
  }

  .custom-datepicker {
    width: 100% !important;
    margin-bottom: 10px !important;
    padding: 7px !important;
    height: 54px !important;
    border-radius: 25px !important;
    padding-left: 25px !important;
    border: 1px solid #dee2e6;
  }
}


/* Whatsapp Number */

.whatsapp-btn {
  display: inline-block;
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  border-radius: 50%;
  font-size: 60px;
  position: absolute;
  color: white;

}

.whatsapp-btn::before {
  content: "";
  height: 85px;
  width: 85px;
  position: absolute;
  top: 0;
  left: 0;
  /* background: var(--primary-color); */
  border-radius: 50%;
  animation: choose 1.6s ease-out infinite;
}


.whatsappIcon-btn {
  display: inline-block;
  height: 90px;
  width: 90px;
  line-height: 74px;
  background: #25d366;
  border-radius: 50%;
  text-align: center;
  color: white;
  position: relative;
  transition: .4s;
}

.WhatsappNumber {
  position: fixed;
  top: 85%;
  right: 9%;
  z-index: 1000;
}

/* Phone Number */


.Phone-btn {
  display: inline-block;
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  border-radius: 50%;
  font-size: 60px;
  position: absolute;

}

.Phone-btn::before {
  content: "";
  height: 85px;
  width: 85px;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--primary-color);
  border-radius: 50%;
  animation: choose 1.6s ease-out infinite;
}


.PhoneIcon-btn {
  display: inline-block;
  height: 90px;
  width: 90px;
  line-height: 74px;
  background: var(--primary-color);
  border-radius: 50%;
  text-align: center;
  color: white;
  position: relative;
  transition: .4s;
}

.PhoneNumber {
  position: fixed;
  top: 85%;
  left: 3%;
  z-index: 1000;
}

.whatsapp-btn a {
  color: white !important;
}

.Phone-btn a {
  color: white !important;
}